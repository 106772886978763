import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  useTheme,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAppData } from 'redux/features/globalData-slice';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import axios, { API } from 'lib/config/axios';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import LightBulbHint from 'views/common/components/UI/LightBulbHint';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import ForgotPasswordDialog from 'views/common/components/Logical/Layout/TopBar/ForgotPasswordDialog';
import SocialLogin from 'views/common/components/Logical/Auth/SocialLogin';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { SignTextField } from 'views/user-module/common/components/UI/SignTextField';
import EmailIcon from '@mui/icons-material/Email';
import dynamic from 'next/dynamic';

const WindowOpener = dynamic(() => import('lib/utils/WindowOpener'), {
  ssr: false,
});

const PREFIX = 'SignInForm';

const classes = {
  forgotPass: `${PREFIX}-forgotPass`,
  signUp: `${PREFIX}-signUp`,
  linkBold: `${PREFIX}-linkBold`,
  rememberMe: `${PREFIX}-rememberMe`,
  orText: `${PREFIX}-orText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.forgotPass}`]: {
    color: theme.palette.grey[300],
    fontWeight: '500',
    letterSpacing: theme.spacing(0.125),
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',

    '&:hover': {
      textDecoration: 'underline',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(14),
      display: 'block',
      textAlign: 'center',
    },
  },
  [`& .${classes.rememberMe}`]: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.grey[300],
    },
  },

  [`& .${classes.signUp}`]: {
    letterSpacing: theme.spacing(0.125),
    margin: theme.spacing(0, 2),
    fontWeight: '500',
    textDecoration: 'underline',
    textTransform: 'uppercase',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  [`& .${classes.linkBold}`]: {
    fontWeight: '700',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.orText}`]: {
    position: 'relative',
    textTransform: 'uppercase',
    '&:before, &:after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '47%',
      height: 1,
      backgroundColor: theme.palette.secondary.light,
    },
    '&:before': {
      left: 0,
    },
    '&:after': {
      right: 0,
    },
  },
}));

const SignInForm = ({
  redirectCallback,
  showSocialLogin = true,
  showSignUpLink = true,
  showLightBulbHint = true,
  signUpLinkAction,
  sso = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('fe_er_auth');
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  const appNavigator = useAppNavigator();
  const appState = useSelector((state) => state.globalData.appState);
  const [formData, setFormData] = useState({
    email:
      (appNavigator.query.email_address
        && decodeURIComponent(appNavigator.query.email_address))
      || '',
    password: '',
  });
  const [forgotPass, setForgotPass] = React.useState({
    open: false,
    render: false,
  }); // ForgotPassword Dialog

  const signInUrl = useMemo(
    () => `${appNavigator.getUrl(
      undefined,
      PATHS.users.signIn().url,
      false,
    )}?return_url=${window.location.origin}/auth/sso&hideLayout=1`,
    [],
  );
  const signUpUrl = useMemo(
    () => `${appNavigator.getUrl(
      undefined,
      PATHS.users.signUp().url,
      false,
    )}?return_url=${window.location.origin}/auth/sso&hideLayout=1`,
    [],
  );
  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleRememberMeCheckBox = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSSOOver = (error, res) => {
    if (res && !error) {
      redirectCallback();
    }
  };

  useEffect(() => {
    // handleFirstload();
  }, []);
  const goToSignUp = () => {
    appNavigator.push(PATHS.motorcycleClub.signUp({ ...appNavigator.query }));
  };
  const handleSignIn = (authenticityToken) => {
    axios
      .post(API.users.signIn, {
        authenticity_token: authenticityToken,
        user: { ...formData },
      })
      .then((res) => {
        localStorage.setItem('signin', true);
        localStorage.setItem('signin-msg', res.data.message);
        if (typeof ReactNativeWebView !== 'undefined') {
          // eslint-disable-next-line no-undef
          ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'login',
              data: res.data,
            }),
          );
        }
        dispatch(setAppData({ ...appState, ...res.data }));
        redirectCallback(res.data);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error.response.data.message,
                }),
              );
              break;
            default:
              handleErrorResponse(error);
          }
        } catch (e) {
          handleErrorResponse(error);
        }
        dispatch(setLoading(false));
      });
  };

  const getToken = () => {
    dispatch(setLoading(true));
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignIn(res.data.authenticity_token);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch(setLoading(false));
      });
  };
  return (
    <Root>
      {sso ? (
        <Box my={2}>
          <WindowOpener
            url={signInUrl}
            bridge={handleSSOOver}
            width={600}
            height={800}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<EmailIcon />}
              color="primary">
              {t('fe_er_auth:sign_in')}
            </Button>
          </WindowOpener>
        </Box>
      ) : (
        <>
          <Box mt={2}>
            {showLightBulbHint && (
              <LightBulbHint>
                {t('fe_er_auth:already_have_an')}

                <NavigatorLink
                  target="_blank"
                  href="https://www.eagleshare.com/"
                  className={classes.linkBold}>
                  {'Eagleshare.com '}
                </NavigatorLink>

                {t('fe_er_auth:user_account')}
              </LightBulbHint>
            )}
          </Box>
          <Box mt={2}>
            <SignTextField
              variant="filled"
              type="email"
              fullWidth
              color="secondary"
              id="email"
              name="email"
              label={t('fe_er_auth:email')}
              value={formData.email}
              onChange={handleOnChange}
            />
          </Box>
          <Box mt={2}>
            <SignTextField
              variant="filled"
              type="password"
              fullWidth
              color="secondary"
              name="password"
              id="password"
              label={t('fe_er_auth:password')}
              value={formData.password}
              onChange={handleOnChange}
            />
          </Box>
          <Box
            mt={2}
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="space-between">
            <FormControlLabel
              className={classes.rememberMe}
              control={(
                <Checkbox
                  name="remember_me"
                  onChange={handleRememberMeCheckBox}
                />
              )}
              label={t('fe_er_auth:remember_me')}
            />
            <Button
              className={classes.forgotPass}
              onClick={() => {
                setForgotPass({ open: true, render: true });
              }}>
              {t('fe_er_auth:forgot_password_link')}
            </Button>
          </Box>

          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={getToken}
              color="primary">
              {t('fe_er_auth:sign_in')}
            </Button>
          </Box>
        </>
      )}
      {showSocialLogin && (
        <>
          <Box
            my={2}
            textAlign="center"
            color={theme.palette.grey[300]}
            fontSize=".875rem"
            className={classes.orText}>
            {t('fe_er_auth:or')}
          </Box>
          {/* NB: login with gmail facebook */}
          <SocialLogin />
        </>
      )}
      {showSignUpLink && (
        <Box
          display="flex"
          alignItems="center"
          justifycontent="center"
          flexWrap="wrap"
          style={{ justifyContent: 'center' }}>
          <Box mx={2} textAlign="center">
            <Typography variant="body1">
              {t('fe_er_auth:dont_have_account')}
            </Typography>
          </Box>
          {sso ? (
            <WindowOpener
              url={signUpUrl}
              bridge={handleSSOOver}
              width={600}
              height={800}>
              <Button className={classes.signUp}>
                {t('fe_er_auth:sign_up')}
              </Button>
            </WindowOpener>
          ) : (
            <Button
              className={classes.signUp}
              onClick={signUpLinkAction || goToSignUp}>
              {t('fe_er_auth:sign_up')}
            </Button>
          )}
        </Box>
      )}
      {forgotPass.render && (
        <ForgotPasswordDialog
          open={forgotPass.open}
          onClose={() => setForgotPass({ ...forgotPass, open: false })}
        />
      )}
    </Root>
  );
};

export default SignInForm;
